import Vue from 'vue';
import axios, { AxiosRequestConfig } from 'axios';
import { endpoints } from '@/utils/endpoints';
import { StreamingPlatforms } from '@/utils/enums';
import { isrcIsValid, getISRCFromPseudoISRC } from '@/utils/AppleMusicPlayer';

export interface TransitionsState {
  transitions: {[key: string]: object},
  fetchingRelatedSongs: boolean,
  userTransitions: [],
}

const initialState: TransitionsState = {
  transitions: {},
  fetchingRelatedSongs: false,
  userTransitions: [],
};

const getters = {
  transitionsForISRCId: (state: TransitionsState) => (ISRCId: string) => {
    const transitions = state.transitions[ISRCId];
    if (transitions === undefined) {
      return null;
    }
    return transitions;
  },
  transitions: (state: TransitionsState) => state.transitions,
  fetchingRelatedSongs: (state: TransitionsState) => state.fetchingRelatedSongs,
  userTransitions: (state: TransitionsState) => state.userTransitions,
};

const actions = {
  async fetchUsersTransitions(context: any) {
    const response = await axios.get(endpoints.getUsersSavedTransitions, {
      withCredentials: true,
    });
    const userTransitions : Array<any> = [];
    response.data.forEach((entry: any) => {
      const { Id } = entry;
      const fromISRC = entry.FromSong.ISRCIdentifier;
      const toISRC = entry.ToSong.ISRCIdentifier;
      userTransitions.push({
        Id,
        From: fromISRC,
        To: toISRC,
      });
    });
    context.commit('setUserTransitions', userTransitions);
  },
  async fetchTransitionsForISRCId(context: any, { ISRCId }: { ISRCId: string }) {
    try {
      context.commit('setFetchingRelatedSongs', { newValue: true });
      const realISRC = getISRCFromPseudoISRC(ISRCId);
      const config: AxiosRequestConfig = {
        params: {
          FromSong: realISRC,
        },
        withCredentials: true,
      };
      const response = await axios.get(endpoints.getTransitionsForISRC, config);
      const hits = response.data.toISRCIds;
      context.commit('setRelatedSongs', { ISRCId, related: hits });
    } finally {
      context.commit('setFetchingRelatedSongs', { newValue: false });
    }
  },
  async createTransition(_:any, { fromMediaItem, toMediaItem }:
    { fromMediaItem: MusicKit.MediaItem, toMediaItem: MusicKit.MediaItem}) {
    if (!isrcIsValid(fromMediaItem.attributes.isrc)) {
      throw Error(`ISRC could not be validated for: ${fromMediaItem.attributes}`);
    }
    if (!isrcIsValid(toMediaItem.attributes.isrc)) {
      throw Error(`ISRC could not be validated for: ${toMediaItem.attributes}`);
    }
    const payload = {
      FromSong: {
        IdInPlatform: fromMediaItem.id,
        Title: fromMediaItem.attributes.name,
        Artist: fromMediaItem.attributes.artistName,
        Platform: StreamingPlatforms.AppleMusic,
        ISRCIdentifier: fromMediaItem.attributes.isrc,
      },
      ToSong: {
        IdInPlatform: toMediaItem.id,
        Title: toMediaItem.attributes.name,
        Artist: toMediaItem.attributes.artistName,
        Platform: StreamingPlatforms.AppleMusic,
        ISRCIdentifier: toMediaItem.attributes.isrc,
      },
    };
    await axios.post(endpoints.createConnection, payload, {
      withCredentials: true,
    });
  },
};

const mutations = {
  setSearchResults(state: TransitionsState, { searchResults }: { searchResults: [] }) {
    Vue.set(state, 'searchResults', searchResults);
  },
  setRelatedSongs(state: TransitionsState, { ISRCId, related }: { ISRCId: string, related: []}) {
    Vue.set(state.transitions, ISRCId, related);
  },
  setFetchingRelatedSongs(state: TransitionsState, { newValue }: { newValue: boolean }) {
    state.fetchingRelatedSongs = newValue;
  },
  setUserTransitions(state: TransitionsState, newValue: any) {
    Vue.set(state, 'userTransitions', newValue);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
