enum LoginResponse {
  Authorized,
  WrongCredentials,
  ServerError
}

enum SearchProgress {
  NoOngoing = 'NoOngoing',
  SearchOngoing = 'SearchOngoing',
}

enum StreamingPlatformInteractionProgress {
  NoOngoing = 'NoOngoing',
  Ongoing = 'Ongoing',
  Failed = 'Failed'
}

enum LoginProgress {
  AwaitingServerResponse,
  WrongUsernameOrPassword,
  LoginSucceded,
  Failed,
}

enum CreateConnectionDirection {
  ConnectToSelected,
  ConnectFromSelected,
}

enum StreamingPlatforms {
  AppleMusic = 'AppleMusic',
  Spotify = 'Spotify',
}

enum BackendInteractionProgress {
  Ongoing = 'Ongoing',
  Failed = 'Failed',
  NoOngoing = 'NoOngoing',
  Successfull = 'Successfull',
}

// These enums must match enums in backend.
enum ReactionTypes {
  Love = 'Love',
  Whoa = 'Whoa',
}

function iconForReactionType(reactionType: string): string {
  switch (reactionType) {
    case ReactionTypes.Whoa:
      return '😮';
    case ReactionTypes.Love:
      return '♥️';
    default:
      return '🎧';
  }
}

export {
  SearchProgress,
  CreateConnectionDirection,
  StreamingPlatforms,
  LoginProgress,
  ReactionTypes,
  iconForReactionType,
  StreamingPlatformInteractionProgress,
  BackendInteractionProgress,
  LoginResponse,
};
