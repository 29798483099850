<template>
    <div>
    <v-row
      justify="center"
    >
        <v-col
          class="d-flex justify-end mt-1"
          style="text-align: right;"
          cols="4"
        >
          {{ fromTitle }} <br>
          {{ fromArtistName }}
          <v-img
            class="ml-2"
            v-if="this.fromArtworkURL !== null"
            :src="this.fromArtworkURL"
            max-height="45"
            max-width="45"
            @click="goToAlbum(fromMediaItem)"
          />
        </v-col>

        <v-col
          cols="1"
          align="center"
        >
          <v-icon>
            mdi-arrow-expand-right
          </v-icon>
        </v-col>

        <v-col
          cols="4"
          class="d-flex justify-left"
          style="text-align: left;"
        >
          <!-- Show album -->
          <v-img
            class="mr-2"
            v-if="this.toArtworkURL !== null"
            :src="this.toArtworkURL"
            max-height="45"
            max-width="45"
            @click="goToAlbum(toMediaItem)"
        />
          <!-- Show title and artist -->
          {{ toTitle }} <br>
          {{ toArtistName }}
        </v-col>
    </v-row>
    <v-divider/>
    </div>
</template>


<script>
import {
  getSongsByISRCId, getISRCFromPseudoISRC,
} from '@/utils/AppleMusicPlayer';

export default {
  name: 'SavedTransitions',
  props: {
    fromISRC: {
      type: String,
      required: true,
    },
    toISRC: {
      type: String,
      required: true,
    },
  },
  computed: {
    fromArtistName() {
      if (this.fromMediaItem === null) {
        return null;
      }
      return this.fromMediaItem.attributes.artistName;
    },
    toArtistName() {
      if (this.toMediaItem === null) {
        return null;
      }
      return this.toMediaItem.attributes.artistName;
    },
    fromTitle() {
      if (this.fromMediaItem === null) {
        return null;
      }
      return this.fromMediaItem.attributes.name;
    },
    toTitle() {
      if (this.toMediaItem === null) {
        return null;
      }
      return this.toMediaItem.attributes.name;
    },
    fromArtworkURL() {
      if (this.fromMediaItem === null) {
        return null;
      }
      return MusicKit.formatArtworkURL(this.fromMediaItem.attributes.artwork, 45, 45);
    },
    toArtworkURL() {
      if (this.toMediaItem === null) {
        return null;
      }
      return MusicKit.formatArtworkURL(this.toMediaItem.attributes.artwork, 45, 45);
    },

  },
  data() {
    return {
      fromMediaItem: null,
      toMediaItem: null,
    };
  },
  mounted() {
    // Start fetching from-mediaitem and to-mediaitem
    const self = this;
    getSongsByISRCId(this.fromISRC)
      .then((fromMediaItem) => { self.fromMediaItem = fromMediaItem[0]; });
    getSongsByISRCId(this.toISRC).then((toMediaItem) => { self.toMediaItem = toMediaItem[0]; });
  },
  methods: {
    goToAlbum(mediaItem) {
      const pureISRC = getISRCFromPseudoISRC(mediaItem.attributes.isrc);
      this.$router.push({
        name: 'Song',
        params: {
          isrcId: pureISRC,
        },
      });
    },
  },
};
</script>
