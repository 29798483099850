<template>
  <v-app>

    <v-app-bar
      app
      dark
      elevation="0"
      clipped-left
    >
      <v-app-bar-nav-icon @click="toggleShowNavigationDrawer()" />
      <v-spacer/>
      <v-col
        cols="4"
      >
        <v-text-field
        id="searchTextField"
        v-model="searchTextComponent"
        placeholder="Search for tracks or artists"
        @input="debounceSearch"
        autocomplete="off"
        :loading="fetchingSearchResults"
        append-icon="mdi-magnify"
      />
      </v-col>
      <v-menu
        offset-y
        max-height="400"
        v-model="showMenu"
        right
        activator="#searchTextField"
      >
        <v-list two-line subheader v-if="searchResults.length > 0">
          <SearchHit
            v-for="element in searchResults"
            :key="element.id"
            v-bind:searchHit="element"
          ></SearchHit>
        </v-list>
      </v-menu>
    </v-app-bar>


    <sidebar></sidebar>

  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer app>
    <!-- -->
  </v-footer>

  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import Sidebar from '@/layout/Sidebar.vue';
import SearchHit from '@/components/SearchHit.vue';

export default {
  name: 'Home',
  components: {
    Sidebar,
    SearchHit,
  },
  data() {
    return {
      showMenu: false,
      searchDebouncer: null,
    };
  },
  created() {
    this.searchDebouncer = debounce(this.startSearch, 200, { leading: false, trailing: true });
  },
  computed: {
    ...mapGetters('search', [
      'searchText',
      'searchResults',
      'searchStatus',
      'fetchingSearchResults',
    ]),
    searchTextComponent: {
      get() {
        return this.searchText;
      },
      set(newValue) {
        this.setSearchText(newValue);
      },
    },
  },
  methods: {
    ...mapMutations('ui', [
      'toggleShowNavigationDrawer',
    ]),
    ...mapActions('search', [
      'startSearch',
    ]),
    ...mapMutations('search', [
      'setSearchText',
    ]),
    debounceSearch() {
      this.showMenu = this.searchText !== null && this.searchText.length > 0;
      this.searchDebouncer.cancel();
      this.searchDebouncer();
    },
  },
};
</script>
