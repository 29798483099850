
import { StreamingPlatforms } from '@/utils/enums';

interface PlayersState {
  players: {
    [key: string]: object
  }
}

const initialState = {
  players: {
    AppleMusic: {
      authorized: false,
    },
  },
};

const actions = {
  async authorizeAppleMusic() {
    const musicKit = MusicKit.getInstance();
    const authorizationResult = await musicKit.authorize();
    // console.log(authorizationResult);
  },
  async disconnectAppleMusic() {
    MusicKit.getInstance().unauthorize();
  },
};

const mutations = {
  setAppleMusicAuthorized(state: PlayersState, newValue: any) {
    state.players[StreamingPlatforms.AppleMusic] = newValue;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
