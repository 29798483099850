<template>
    <v-container>
      <v-row
        justify="center"
      >
        <v-col
          cols="auto"
        >
          <router-link :to="goToPath"
            v-if="this.playback.mediaItem !== null"
          >
            <v-img
                v-if="this.artworkURL !== null"
                :src="this.artworkURL"
                max-height="150"
                max-width="150"
            />
          </router-link>
        </v-col>
      </v-row>
      <v-row
        justify="center"
      >

        <v-col cols="auto">
          <h4
            v-if="currentTitle !== null"
            style="text-align:center"
          >
              {{ currentTitle | allCaps | truncate(29) }}
          </h4>

          <h4
            style="text-align:center"
          >
            <router-link
              style="text-decoration: none; color: black"
              v-if="currentArtist !== null"
              :to="artistPath"
            >
              {{ currentArtist | truncate(29) }}
            </router-link>
          </h4>

          <h5
            v-if="currentAlbumTitle !== null"
            style="text-align:center"
          >
              {{ currentAlbumTitle | truncate(32) }}
          </h5>
        </v-col>
      </v-row>

      <v-row
        justify="center"
      >
        <v-col>
          <v-btn
            v-if="playback.mediaItem !== null"
            icon
            @click="wrapLikeSong()"
          >
            <v-icon
              v-if="songIsLiked"
              color="rgb(210,40,20)"
            >
              mdi-heart
            </v-icon>
            <v-icon
              v-if="!songIsLiked"
              color="rgb(1,1,1)"
            >
              mdi-heart-outline
            </v-icon>
          </v-btn>
          </v-col>
          <v-spacer/>
          <v-col
            cols="auto"
          >
            <v-btn
                icon
                @click="skipToPrevious()"
            >
                <v-icon color="grey lighten-1">
                mdi-skip-previous
                </v-icon>
            </v-btn>

            <v-btn
                v-if="!isPlaying"
                icon
                @click="play()"
            >
                <v-icon color="grey lighten-1">
                mdi-play
                </v-icon>
            </v-btn>

            <v-btn
                v-if="isPlaying"
                icon
                @click="pause()"
            >
                <v-icon color="grey lighten-1">
                mdi-pause
                </v-icon>
            </v-btn>

            <v-btn
                icon
                @click="skipToNext()"
            >
                <v-icon color="grey lighten-1">
                mdi-skip-next
                </v-icon>
            </v-btn>
        </v-col>
        <v-spacer/>

        <v-col
          cols="auto"
        >
          <v-menu
            v-model="showVolumeSlider"
            top
            offset-y
          >

            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>{{iconForVolume}}</v-icon>
              </v-btn>
            </template>

            <v-slider
              vertical
              v-model="volume"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-slider
            v-if="this.playback.progressPercentage !== null && !isLoading && !isWaiting"
            min="0"
            max="1"
            step="0.01"
            :value="this.playback.progressPercentage"
            @change="newValue => seekNewValue(newValue)"
            hide-details=""
          />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  playSong, appendToQueue, addToNextInQueue, fetchAlbumsForSong, setVolume, getISRCFromPseudoISRC,
  seekToProgress,
} from '@/utils/AppleMusicPlayer';

export default {
  name: 'Player',
  data() {
    return {
      volume: 100,
      showVolumeSlider: false,
      playback: {
        progressPercentage: null,
        playbackState: false,
        mediaItem: null,
      },
    };
  },
  computed: {
    ...mapGetters('reactions', [
      'getReactionForIsrcId',
    ]),
    iconForVolume() {
      const { volume } = this;
      if (volume > 70) {
        return 'mdi-volume-high';
      } if (volume > 30) {
        return 'mdi-volume-medium';
      } if (volume > 0) {
        return 'mdi-volume-low';
      }
      return 'mdi-volume-mute';
    },
    currentTitle() {
      return this.playback.mediaItem !== null ? this.playback.mediaItem.attributes.name : null;
    },
    currentAlbumTitle() {
      return this.playback.mediaItem !== null ? this.playback.mediaItem.albumName : null;
    },
    currentArtist() {
      return this.playback.mediaItem !== null
        ? this.playback.mediaItem.attributes.artistName : null;
    },
    duration() {
      return this.playback.mediaItem !== null ? this.playback.mediaItem.duration : null;
    },
    isPlaying() {
      return this.playback.playbackState === 'playing';
    },
    artworkURL() {
      if (this.playback.mediaItem !== null) {
        return MusicKit.formatArtworkURL(this.playback.mediaItem.attributes.artwork,
          this.artworkHeight,
          this.artworkHeight);
      }
      return null;
    },
    artworkHeight() {
      return 200.0;
    },
    isPaused() {
      return this.playback.playbackState === 'paused';
    },
    isLoading() {
      return this.playback.playbackState === 'loading';
    },
    isWaiting() {
      return this.playback.playbackState === 'waiting';
    },
    goToPath() {
      const pureISRC = getISRCFromPseudoISRC(this.isrcId);
      return {
        name: 'Song',
        params: {
          isrcId: pureISRC,
        },
      };
    },
    artistPath() {
      if (this.playback.mediaItem.relationships === undefined) {
        debugger;
        return 'Cannot compute';
      }
      const artistsOnSong = this.playback.mediaItem.relationships.artists.data;
      if (artistsOnSong !== undefined && artistsOnSong.length > 0) {
        const artist = artistsOnSong[0];
        const artistId = artist.id;
        return {
          name: 'Artist',
          params: {
            artistId,
          },
        };
      }
      // We could not figure out the artist ID.
      return null;
    },
    isrcId() {
      return this.playback.mediaItem.attributes.isrc;
    },
    songIsLiked() {
      return this.getReactionForIsrcId(this.isrcId) !== undefined;
    },
  },
  watch: {
    volume: newValue => setVolume(newValue),
  },
  mounted() {
    // Events: https://developer.apple.com/documentation/musickitjs/musickit/events
    MusicKit.getInstance().addEventListener('playbackProgressDidChange', event => this.updatePlaybackTime(event));
    MusicKit.getInstance().addEventListener('mediaItemDidChange', event => this.updateCurrentlyPlayingItem(event));
    MusicKit.getInstance()
      .addEventListener(MusicKit.Events.playbackStateDidChange,
        event => this.updatePlayingOrPaused(event));
    // MusicKit.getInstance().player
    //   .addEventListener(MusicKit.Events.playbackVolumeDidChange,
    //     event => this.volumeChanged(event));
  },
  methods: {
    ...mapActions('reactions', [
      'likeSong',
    ]),
    updatePlaybackTime(currentPlaybackTime) {
      this.playback.progressPercentage = currentPlaybackTime.progress;
    },
    async updateCurrentlyPlayingItem(event) {
      if (event.item.relationships === undefined) {
        /* In-elegant: I still don't understand when or why this (relationships being undefined)
        happens, but I _do_ know a remedy.
        Example: Can be triggered when playing 'C O O L Le Youth' from search panel
        */
        const fullyPopoulatedMediaItem = await MusicKit.getInstance().api.song(event.item.id);
        this.playback.mediaItem = fullyPopoulatedMediaItem;
      } else {
        this.playback.mediaItem = event.item;
      }
    },
    updatePlayingOrPaused(event) {
      if (!event || !event.state) {
        // console.log('No event');
        return;
      }
      switch (event.state) {
        case 0:
          // console.log('None');
          break;
        case 1:
          // console.log('Loading');
          break;
        case 2:
          // console.log('Playing');
          break;
        case 3:
          // console.log('Paused');
          break;
        case 4:
          // console.log('Stopped');
          break;
        case 5:
          // console.log('Ended');
          this.playback.mediaItem = null;
          this.playback.progressPercentage = null;
          break;
        case 6:
          // console.log('Seeking');
          break;
        case 8:
          // console.log('Waiting');
          break;
        case 9:
          // console.log('Stalled');
          break;
        case 10:
          // console.log('Completed');
          break;
        default:
          // console.log('Not handled: ', event.state);
      }
      this.playback.playbackState = MusicKit.PlaybackStates[event.state];
      console.log('New state: ', this.playback.playbackState);
    },
    skipToPrevious() {
      MusicKit.getInstance().player.skipToPreviousItem();
    },
    skipToNext() {
      MusicKit.getInstance().player.skipToNextItem();
    },
    pause() {
      const state = MusicKit.getInstance().player.playbackState;
      MusicKit.getInstance().player.pause();
    },
    play() {
      const state = MusicKit.getInstance().player.playbackState;
      MusicKit.getInstance().player.play();
    },
    wrapLikeSong() {
      this.likeSong(this.playback.mediaItem);
    },
    toggleShowVolumeSlider() {
      this.showVolumeSlider = !this.showVolumeSlider;
    },
    seekNewValue(newValue) {
      // Convert from percentage to seconds
      const durationSeconds = this.playback.mediaItem.attributes.durationInMillis / 1000;
      seekToProgress(durationSeconds * newValue);
    },
  },
};
</script>
