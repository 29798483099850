<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon v-if="isAlbum">
        mdi-music-box-multiple-outline
      </v-icon>
      <v-icon v-else-if="isTrack">
        mdi-music-box-outline
      </v-icon>
    </v-list-item-avatar>

    <v-list-item-content @click="expandSong()">
        <v-list-item-title v-text="searchHit.attributes.name"></v-list-item-title>
        <v-list-item-subtitle v-text="searchHit.attributes.artistName"></v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        icon
        @click="wrapPlaySong()"
      >
        <v-icon color="grey lighten-1">mdi-play</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="invokeAppendToQueue()"
      >
        <v-icon color="grey lighten-1">mdi-playlist-plus</v-icon>
      </v-btn>
      </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapMutations } from 'vuex';
import Vue from 'vue';
import { playSong, appendToQueue } from '@/utils/AppleMusicPlayer';

export default Vue.extend({
  name: 'SearchHit',
  props: {
    searchHit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.searchHit.attributes.name;
    },
    artist() {
      return this.searchHit.attributes.artistName;
    },
    itemType() {
      return this.searchHit.type;
    },
    isAlbum() {
      return this.itemType === 'albums';
    },
    isTrack() {
      return this.itemType === 'songs';
    },
  },
  methods: {
    async wrapPlaySong() {
      playSong(this.searchHit);
    },
    invokeAppendToQueue() {
      appendToQueue(this.searchHit);
    },
    expandSong() {
      this.$router.push({
        name: 'Song',
        params: {
          isrcId: this.searchHit.attributes.isrc,
        },
      });
    },
  },
});
</script>
