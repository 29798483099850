<template>
  <v-app>
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-btn
              outlined
                @click="confirmAccount"
                :disabled="accountConfirmationResult==='IN_PROGRESS'"
                :loading="accountConfirmationResult==='IN_PROGRESS'"
            >
                Click here to confirm your account
            </v-btn>
          </v-flex>
          <v-alert v-if="accountConfirmationResult==='OK'" type="success">
            Account confirmed. <br>
            We'll transfer you to Login page shortly
            </v-alert>
            <v-alert v-if="accountConfirmationResult==='ERROR'" type="error">
            Account confirmation failed. You may try again, or get in touch with us.
            </v-alert>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import { endpoints } from '@/utils/endpoints';

export default {
  name: 'ConfirmSignUp',
  data() {
    return {
      accountConfirmationResult: null,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async confirmAccount() {
      this.accountConfirmationResult = 'IN_PROGRESS';
      // console.log('Confirming account');
      const { confirmationId } = this.$route.params;
      const response = await axios.post(endpoints.confirmAccount, {
        confirmUUID: confirmationId,
      });
      if (response.status === 200) {
        this.accountConfirmationResult = 'SUCCESS';
        setTimeout(() => this.$router.push({ name: 'Login' }, 4500));
      } else if (response.status === 404) {
        this.accountConfirmationResult = 'ERROR';
      }
    },
    goToSignup() {
      this.$router.push({ name: 'SignUp' });
    },
  },
};
</script>
