export const MY_ICONS = {
  'account-circle': '',
  user: '',
  lock: '',
  at: '',
  magnify: '',
  heart: '',
  'email-outline': '',
  'heart-off-outline': '',
  'arrow-expand-right': '',
  'heart-outline': '',
  'skip-next': '',
  'skip-previous': '',
  pause: '',
  play: '',
  'playlist-plus': '',
  'swap-horizontal': '',
  'arrow-down': '',
  'exit-run': '',
  'music-box-multiple-outline': '', // album
  'music-box-outline': '',
  'chevron-right': '', // next-arrow >
  'chevron-left': '', // previous-arrow <
  'volume-high': '',
  'volume-medium': '',
  'volume-low': '',
  'volume-mute': '',
};
