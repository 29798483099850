<template>
  <div class="home">
    <h1>Account</h1>
    <h2>Apple Music</h2>
    <v-btn
      v-if="!appleMusicAuthorised"
      @click="authorizeAppleMusic"
    >
      Click to connect your Apple Music subscription
    </v-btn>
    <v-dialog
      v-if="appleMusicAuthorised"
      v-model="showDisconnectAppleMusic"
      width="700"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Disonnect Apple Music for Edjy
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          Disconnect Apple Music for Edjy
        </v-card-title>
        <v-divider/>
        <v-spacer/>

        <v-card-text>
          Click Disconnect to disconnect Apple Music for Edjy. <br>
          After this you will still be able to use your Edjy account, but you will only be able to
          hear tracks in their preview-length, typically 30 seconds. <br>
          If you change your mind later on, you can reconnect your Apple Music with Edjy again.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=""
            text
            @click="disconnectAppleMusic"
          >
            Disconnect
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import auth from '../../store/auth';

export default {
  name: 'Account',
  computed: {
    appleMusicAuthorised() {
      return MusicKit.getInstance().isAuthorized;
    },
  },
  created() {
    // Set up event listeners for authorization changes
    const authorizationStatusDidChangeEvent = MusicKit.Events.authorizationStatusDidChange;
    MusicKit.getInstance().addEventListener(authorizationStatusDidChangeEvent, (event) => {
      this.updateAppleMusicAuthStatus(event);
    });
  },
  methods: {
    ...mapActions('players', [
      'authorizeAppleMusic',
      'disconnectAppleMusic',
    ]),
    ...mapMutations('players', [
      'setAppleMusicAuthorized',
    ]),
    updateAppleMusicAuthStatus(event) {
      const newAppleAuthStatus = MusicKit.isAuthorized;
      this.setAppleMusicAuthorized(newAppleAuthStatus);
    },
  },
};
</script>
