<template>
<v-app>
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-alert
              v-if="providedWrongCredentials"
              type="error"
            >
              Wrong username/password combination
            </v-alert>
            <v-alert
              v-if="serverErrorOccured"
              type="warning"
            >
              A server error occured
            </v-alert>

            <v-card>
              <v-card-title>
                Sign in
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    name="login"
                    prepend-icon="mdi-account-circle"
                    type="text"
                    autocomplete="username"
                    v-model="username"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    autocomplete="password"
                    v-model="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  outlined
                  v-on:click="goToRestorePassword"
                >
                  Forgot your password?
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-on:click="attemptSignin"
                  :loading="loginInProgress"
                  :disabled="loginInProgress"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </v-main>
</v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LoginProgress, LoginResponse } from '@/utils/enums';

export default {
  name: 'SignInPage',
  data() {
    return {
      username: '',
      password: '',
      loginResult: null,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'loginProgress',
    ]),
    loginInProgress() {
      return this.loginProgress === LoginProgress.AwaitingServerResponse;
    },
    providedWrongCredentials() {
      return this.loginResult !== null && this.loginResult === LoginResponse.WrongCredentials;
    },
    serverErrorOccured() {
      return this.loginResult !== null && this.loginResult === LoginResponse.ServerError;
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    async attemptSignin() {
      this.loginResult = null;
      const response = await this.login(
        { email: this.username, password: this.password },
      );
      this.loginResult = response;
      if (this.loginResult === LoginResponse.Authorized) {
        // If we have a redirect adress, go there
        if (this.$route.query.redirect !== undefined && this.$route.query.redirect !== '') {
          this.$router.push(this.$route.query.redirect);
        } else {
          this.$router.push({
            name: 'Favorites',
          });
        }
      }
    },
    goToSignup() {
      this.$router.push({ name: 'SignUp' });
    },
    goToRestorePassword() {
      this.$router.push({ name: 'ForgotPassword' });
    },
  },
};
</script>
