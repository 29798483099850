<template>
  <div>
    <h1 v-if="artistName !== null">
      {{ artistName }}
    </h1>
    <album v-for="album in albums" :album="album" :key="album.id"/>
  </div>
</template>

<script>

import Album from '@/components/Album.vue';

export default {
  name: 'ArtistPage',
  components: {
    Album,
  },
  props: {
    artistId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      artistName: null,
      albums: [],
    };
  },
  mounted() {
    this.fetchArtistInfo();
  },
  methods: {
    async fetchArtistInfo() {
      const result = await MusicKit.getInstance().api.artist(this.artistId);
      console.log(result);
      this.artistName = result.attributes.name;
      this.albums = result.relationships.albums.data;
      // eslint-disable-next-line max-len
      this.albums.forEach(album => MusicKit.getInstance().api.album(album.id).then(response => console.log(response)));
      // console.log(result.relationships.albums.data.map(x => x.id));
    },
  },
};
</script>
