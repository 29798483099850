<template>
  <v-app>
    <div id="SignUp">
        <v-main>
          <v-container
            fluid
            fill-height
          >
            <v-layout
              align-center
              justify-center
            >
              <v-flex
                xs12
                sm8
                md4
              >
                <v-alert
                  v-if="serverErrorOccured"
                  type="warning"
                >
                  A server error occured while attempting to create the account.
                </v-alert>
                <v-alert
                  v-else-if="badRequest"
                  type="warning"
                >
                  Something went wrong <br>
                  <template
                    v-if="errorDescription !== null"
                  >
                    {{ errorDescription }}
                  </template>
                </v-alert>
                <v-alert
                  v-else-if="conflict"
                  type="warning"
                >
                  Username or email already in use
                  <template
                    v-if="errorDescription !== null"
                  >
                    {{ errorDescription }}
                  </template>
                </v-alert>
                <v-card>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        label="Username"
                        name="username"
                        prepend-icon="mdi-account-circle"
                        type="text"
                        autocomplete="username"
                        v-model="username"
                        :error-messages="usernameErrorMessages"
                        required
                        @change="$v.username.$touch()"
                        @blur="$v.username.$touch()"
                      />

                      <v-text-field
                        label="Email"
                        name="email"
                        prepend-icon="mdi-at"
                        type="text"
                        autocomplete="email"
                        v-model="email"
                        :error-messages="emailErrorMessages"
                        required
                        @change="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                      />

                      <v-text-field
                        id="password"
                        label="Password"
                        name="password"
                        prepend-icon="mdi-lock"
                        type="password"
                        autocomplete="new-password"
                        v-model="password"
                        :error-messages="passwordErrorMessages"
                        required
                        @change="$v.password.$touch()"
                        @blur="$v.password.$touch()"

                      />
                      <v-text-field
                        id="repeatpassword"
                        label="Confirm password"
                        name="password"
                        prepend-icon="mdi-lock"
                        type="password"
                        autocomplete="new-password"
                        v-model="repeatPassword"
                        :error-messages="repeatPasswordErrorMessages"
                        required
                        @change="$v.repeatPassword.$touch()"
                        @blur="$v.repeatPassword.$touch()"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="$v.$invalid ? true : false"
                        outlined
                        v-on:click="createAccount"
                    >
                        Create account
                    </v-btn>
                    </v-card-actions>
                </v-card>
                <div>
                  <v-alert v-if="accountCreated==='OK'" type="success">
                      Account created. <br>
                      Now go check your email to verify your account.
                  </v-alert>
                  </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-main>
    </div>
  </v-app>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import axios from 'axios';
import { endpoints } from '@/utils/endpoints';

export default {
  mixins: [validationMixin],
  name: 'SignUpPage',
  data() {
    return {
      username: '',
      email: '',
      password: '',
      repeatPassword: '',
      accountCreated: null,
      errorDescription: null,
    };
  },
  computed: {
    serverErrorOccured() {
      return this.accountCreated === 'ERROR';
    },
    badRequest() {
      return this.accountCreated === 'BAD_REQUEST';
    },
    conflict() {
      return this.accountCreated === 'CONFLICT';
    },
    usernameErrorMessages() {
      const errors = [];
      if (!this.$v.username.$dirty) {
        return errors;
      }
      if (!this.$v.username.required) {
        errors.push('Surely you need an awesome username');
      }
      return errors;
    },
    emailErrorMessages() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.email) {
        errors.push('Must be valid e-mail');
      }
      if (!this.$v.email.required) {
        errors.push('You must supply an email');
      }
      return errors;
    },
    passwordErrorMessages() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push('Supply a password');
      }
      return errors;
    },
    repeatPasswordErrorMessages() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) {
        return errors;
      }
      if (!this.$v.repeatPassword.required) {
        errors.push('Supply a password');
      }
      if (!this.$v.repeatPassword.passwordsMatch) {
        errors.push("The passwords don't match");
      }
      return errors;
    },
  },
  methods: {
    async createAccount() {
      this.accountCreated = null;
      const response = await axios.post(endpoints.signUp, {
        username: this.username,
        email: this.email,
        password: this.password,
      }, {
        // Do manual inspection of all statuscodes
        validateStatus: () => true,
      });
      if (response.status === 200) {
        this.accountCreated = 'OK';
        // Navigate to login page
        setTimeout(this.$router.push({ name: 'Login' }), 10000);
      } else if (response.status === 400) {
        this.accountCreated = 'BAD_REQUEST';
        this.errorDescription = response.data.message;
      } else if (response.status === 409) {
        this.accountCreated = 'CONFLICT';
        this.errorDescription = response.data.message;
      } else {
        this.accountCreated = 'ERROR';
      }
    },
  },
  validations: {
    username: { required },
    email: { required, email },
    password: { required },
    repeatPassword: {
      required,
      passwordsMatch: sameAs('password'),
    },
  },
};
</script>
