<template>
  <div>
  <v-row>
    <v-col
      align="center"
    >
      <template
        v-if="selectedMediaItem !== null"
      >
        <h1>
          <v-btn
            icon
            @click="wrapLikeSong()"
          >
            <v-icon
              :color="songIsLiked(isrcId) ? 'rgb(210,40,20)':'rgb(1,1,1)'"
            >
              {{ songIsLiked(isrcId) ? 'mdi-heart': 'mdi-heart-outline'}}
            </v-icon>
          </v-btn>
          {{ songTitle }}
        </h1>
        <h2
            style="text-align:center"
          >
            <router-link
              style="text-decoration: none; color: black"
              v-if="songArtist !== null"
              :to="artistPath"
            >
              {{ songArtist | truncate(29) }}
          </router-link>
        </h2>
        <v-img
          :src="artworkURL"
          width="200"
          height="200"
        />

        <v-btn
          icon
          @click="play()"
        >
          <v-icon>mdi-play</v-icon>
        </v-btn>

        <v-tooltip
          bottom
          activator="#queueSongButton"
        >
          Queue song
        </v-tooltip>
        <v-btn
          id="queueSongButton"
          icon
          @click="queueSong()"
        >
          <v-icon>mdi-playlist-plus</v-icon>
        </v-btn>

        <v-tooltip
          bottom
          activator="#playAsNextButton"
        >
          Queue as next
        </v-tooltip>
        <v-btn
          id="playAsNextButton"
          icon
          @click="addAsNext()"
        >
          <v-icon
            class="icon__flip"
          >
            mdi-playlist-plus
          </v-icon>
        </v-btn>

        <template
          v-if="songAppearsInMoreThanOneAlbum"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="4"
            >
              <v-btn
                icon
                @click="advanceToPreviousAlbum()"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              Album
              <v-btn
                icon
                @click="advanceToNextAlbum()"
              >
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <v-data-table
          :headers="albumSongsTableHeaders"
          :items="tracksInAlbumForSelectedMediaItem"
          hide-default-footer
          hide-default-header
          disable-pagination
          @dblclick:row="pickSong"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
            >
              <v-icon
                small
                :color="songIsLiked(item.attributes.isrc) ? 'rgb(210,40,20)':'rgb(1,1,1)'"
                @click="wrapLikeSongInAlbum(item)"
              >
                {{ songIsLiked(item.attributes.isrc) ? 'mdi-heart': 'mdi-heart-outline'}}
              </v-icon>
            </v-btn>
            <v-icon
              small
              class="mr-2"
              @click="wrapPlaySong(item)"
            >
              mdi-play
            </v-icon>
            <v-icon
              small
              @click="wrapAppendToQueue(item)"
            >
              mdi-playlist-plus
            </v-icon>
          </template>
        </v-data-table>

      </template>
      <v-progress-circular
        indeterminate
        v-else
      />
    </v-col>
  </v-row>
    <v-row>
    <v-col
      align="center"
    >
      <h1>Related songs</h1>
    </v-col>
  </v-row>

  <v-row>
    <v-col
      align="center"
    >
      <v-btn
      color="primary"
      dark
      @click.stop="presentCreateConnectionModal()"
    >
      Create connection
    </v-btn>
    </v-col>
  </v-row>

  <v-progress-circular
    v-if="fetchingRelatedSongs"
    indeterminate
  />
  <template
    v-else-if="mediaItemIsSet && succeederSongs.length > 0"
  >
  <v-row>
    <v-col
      cols="4"
      v-for="item in succeederSongs"
      :key="item"
    >
      <related-song
        :ISRCId="item"
      ></related-song>
    </v-col>
  </v-row>

    <v-row
      v-if="succeederSongs.length > 9"
    >
    <v-col
      align="center"
    >
      <v-btn
      color="primary"
      dark
      @click.stop="shouldPresentCreateConnectionModal = true"
    >
      Create connection
    </v-btn>
    </v-col>
  </v-row>
  </template>

  <create-connection-modal
    :showDialog="shouldPresentCreateConnectionModal"
    :mediaItem=this.selectedMediaItem
    @closeCreateConnectionModal="stopDisplayOfCreateConnectionModal()"
  >
  </create-connection-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import { endpoints } from '@/utils/endpoints';
import { CreateConnectionDirection } from '@/utils/enums';
import {
  playSong, appendToQueue, addToNextInQueue, fetchAlbumsForSong, getSongsByISRCId,
  getISRCFromPseudoISRC, fetchTracksInAlbum,
} from '@/utils/AppleMusicPlayer';
import RelatedSong from '@/components/RelatedSong.vue';
import CreateConnectionModal from '@/views/Modals/CreateConnectionModal.vue';

export default {
  name: 'Song',
  components: {
    RelatedSong,
    CreateConnectionModal,
  },
  data() {
    return {
      shouldPresentCreateConnectionModal: false,
      mediaItemsForIsrcId: [],
      selectedItemIndex: null,
      tracksInAlbumForSelectedMediaItem: [],
      albumSongsTableHeaders: [
        {
          text: 'Title',
          value: 'attributes.name',
          align: 'start',
        },
        {
          text: 'Artist',
          value: 'attributes.artistName',
          align: 'start',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
        }],
    };
  },
  watch: {
    selectedItemIndex(newValue) {
      // Fetch other songs in album
      this.updateTracksForAlbum();
    },
    isrcId(newValue) {
      this.prepComponent();
    },
  },
  computed: {
    ...mapGetters('transitions', [
      'transitionsForISRCId',
      'fetchingRelatedSongs',
    ]),
    ...mapGetters('reactions', [
      'getReactionForIsrcId',
    ]),
    selectedMediaItem() {
      if (this.mediaItemsForIsrcId == null || this.selectedItemIndex === null) {
        return null;
      }
      return this.mediaItemsForIsrcId[this.selectedItemIndex];
    },
    mediaItemIsSet() {
      return this.selectedMediaItem !== null;
    },
    songIsLiked() {
      return songISRCId => this.getReactionForIsrcId(songISRCId) !== undefined;
    },
    succeederSongs() {
      const relatedSongs = this.transitionsForISRCId(this.isrcId);
      if (!relatedSongs) {
        return [];
      }
      return relatedSongs;
    },
    isrcId() {
      const isrcRaw = this.$route.params.isrcId;
      return getISRCFromPseudoISRC(isrcRaw);
    },
    artworkURL() {
      if (this.selectedMediaItem !== null) {
        return MusicKit.formatArtworkURL(this.selectedMediaItem.attributes.artwork, 200, 200);
      }
      throw new Error('Logical error - not artwork to show');
    },
    songTitle() {
      if (this.selectedMediaItem !== null) {
        return this.selectedMediaItem.attributes.name;
      }
      return '';
    },
    songArtist() {
      if (this.selectedMediaItem !== null) {
        return this.selectedMediaItem.attributes.artistName;
      }
      return '';
    },
    artistPath() {
      if (this.selectedMediaItem.relationships === undefined) {
        debugger;
        return 'Cannot compute';
      }
      const artistsOnSong = this.selectedMediaItem.relationships.artists.data;
      if (artistsOnSong !== undefined && artistsOnSong.length > 0) {
        const artist = artistsOnSong[0];
        const artistId = artist.id;
        return {
          name: 'Artist',
          params: {
            artistId,
          },
        };
      }
      // We could not figure out the artist ID.
      return null;
    },
    songAppearsInMoreThanOneAlbum() {
      return this.mediaItemsForIsrcId.length > 1;
    },
  },
  created() {
    this.prepComponent();
  },
  methods: {
    ...mapActions('transitions', [
      'fetchTransitionsForISRCId',
    ]),
    ...mapActions('reactions', [
      'likeSong',
    ]),
    pickSong(_, { item }) {
      const pureISRC = getISRCFromPseudoISRC(item.attributes.isrc);
      this.$router.replace({
        name: 'Song',
        params: {
          isrcId: pureISRC,
        },
      });
    },
    async wrapPlaySong(mediaItem) {
      playSong(mediaItem);
    },
    async updateTracksForAlbum() {
      const albumId = this.selectedMediaItem.relationships.albums.data[0].id;
      const results = await fetchTracksInAlbum(albumId);
      this.tracksInAlbumForSelectedMediaItem = results.data;
    },
    async prepComponent() {
      // Fetch transitions for song
      this.fetchTransitionsForISRCId({ ISRCId: this.isrcId }); // No need to await

      // Fetch all albums having song in it
      this.mediaItemsForIsrcId = await getSongsByISRCId(this.isrcId);
      this.selectedItemIndex = 0;
    },
    play() {
      playSong(this.selectedMediaItem);
    },
    queueSong() {
      appendToQueue(this.selectedMediaItem);
    },
    addAsNext() {
      addToNextInQueue(this.selectedMediaItem);
    },
    wrapLikeSong() {
      this.likeSong(this.selectedMediaItem);
    },
    async wrapLikeSongInAlbum(item) {
      await this.likeSong(item);
    },
    toggleLikeSong() {

    },
    advanceToNextAlbum() {
      this.advanceChosenAlbum(1);
    },
    advanceToPreviousAlbum() {
      this.advanceChosenAlbum(-1);
    },
    advanceChosenAlbum(delta) {
      const numberOfAlbums = this.mediaItemsForIsrcId.length;
      const nextIndexToPick = (numberOfAlbums + this.selectedItemIndex + delta) % numberOfAlbums;
      this.selectedItemIndex = nextIndexToPick;
    },
    stopDisplayOfCreateConnectionModal() {
      this.shouldPresentCreateConnectionModal = false;
    },
    presentCreateConnectionModal() {
      this.shouldPresentCreateConnectionModal = true;
    },
  },
};
</script>


<style scoped>
  .icon__flip {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
</style>
