// Default to development URL, override when in production
let BASE_URL: string = 'https://api.edjy.local';
if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://api.edjy.io';
}


const endpoints = {
  login: `${BASE_URL}/users/signin`,
  signout: `${BASE_URL}/users/signout`,
  signUp: `${BASE_URL}/users/signup/create`,
  requestPasswordResetEmail: `${BASE_URL}/users/requestPasswordResetEmail`,
  updatePassword: `${BASE_URL}/users/updatePassword`,
  confirmAccount: `${BASE_URL}/users/confirmSignup/`,
  createConnection: `${BASE_URL}/transitions`,
  getTransitionsForISRC: `${BASE_URL}/transitions`,
  getUsersSavedTransitions: `${BASE_URL}/reactions/me/transitions`,
  postSongReaction: `${BASE_URL}/reactions/song`,
  getLikes: `${BASE_URL}/reactions/me/songs`,
};

export {
  endpoints,
};
