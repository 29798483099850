<template>
<v-app>
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-alert
              v-if="serverErrorOccured"
              type="warning"
            >
              A server error occured
            </v-alert>

            <v-card>
              <v-card-text>
                <v-form>
                  <v-text-field
                    id="newPassword"
                    label="New password"
                    name="newPassword"
                    prepend-icon="mdi-lock"
                    type="password"
                    autocomplete="new-newPassword"
                    v-model="newPassword"
                    :error-messages="newPasswordErrorMessages"
                    required
                    @change="$v.newPassword.$touch()"
                    @blur="$v.newPassword.$touch()"
                  />
                  <v-text-field
                    id="confirmNewPassword"
                    label="Confirm password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    autocomplete="new-password"
                    v-model="confirmNewPassword"
                    :error-messages="confirmNewPasswordErrorMessages"
                    required
                    @change="$v.confirmNewPassword.$touch()"
                    @blur="$v.confirmNewPassword.$touch()"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="$v.$invalid ? true : false"
                    :loading="backendInteractionInProgress"
                    outlined
                    v-on:click="resetPassword"
                >
                    Update my password
                </v-btn>
                </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </v-main>
</v-app>
</template>

<script>
import Vue from 'vue';
import vuelidate, { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { LoginProgress, LoginResponse } from '@/utils/enums';
import { endpoints } from '@/utils/endpoints';

export default {
  name: 'ResetPassword',
  mixins: [validationMixin],
  props: {
    // resetId is extracted from route (see props: true in router.ts)
    resetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPassword: '',
      confirmNewPassword: null,
      backendInteractionInProgress: false,
    };
  },
  computed: {
    serverErrorOccured() {
      return this.loginResult !== null && this.loginResult === LoginResponse.ServerError;
    },
    newPasswordErrorMessages() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) {
        return errors;
      }
      if (!this.$v.newPassword.required) {
        errors.push('Supply a password');
      }
      return errors;
    },
    confirmNewPasswordErrorMessages() {
      const errors = [];
      if (!this.$v.confirmNewPassword.$dirty) {
        return errors;
      }
      if (!this.$v.confirmNewPassword.required) {
        errors.push('Confirm the password');
      }
      if (!this.$v.confirmNewPassword.passwordsMatch) {
        errors.push("The passwords don't match");
      }
      return errors;
    },
  },
  methods: {
    async resetPassword() {
      // Invoke API
      const payload = {
        password: this.newPassword,
        resetUUId: this.resetId,
      };
      this.backendInteractionInProgress = true;
      try {
        await axios.post(endpoints.updatePassword, payload);
      } catch (error) {
        console.debug("Error while trying to update user's password: ", error);
      } finally {
        this.backendInteractionInProgress = false;
      }
    },
  },
  validations: {
    newPassword: {
      required,
    },
    confirmNewPassword: {
      required,
      passwordsMatch: sameAs('newPassword'),
    },
  },
};
</script>
