<template>
    <v-dialog
        @click:outside="dismissSelf()"
        v-model="showSelf"
        max-width="600px"
    >

    <v-card>
        <v-card-title>
          <span class="headline">Create connection</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="searchTextComponent"
              @input="startSearch"
              :loading="fetchingSearchResults"
              append-icon="mdi-magnify"
            >
            </v-text-field>

            <v-list
              two-line
              v-if="searchResults.length > 0"
              rounded
            >
              <v-list-item-group v-model="selectedItemIndex">
                <v-list-item
                    v-for="(item,i) in searchResults"
                    :key="i"
                >
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="item.attributes.name"
                        />
                        <v-list-item-subtitle
                            v-text="item.attributes.artistName"
                        />
                    </v-list-item-content>

                </v-list-item>
              </v-list-item-group>
            </v-list>

            <template
              v-if="anItemIsSelectedForCreateConnection"
            >
              <v-divider/>
              <div>
                <h3>{{ fromName !== null ? fromName : ''}}</h3>
                <h4>{{ fromArtist !== null? fromArtist : '' }}</h4>
              </div>

              <v-icon color="grey lighten-1">
                mdi-arrow-down
              </v-icon>

              <div>
                <h3>{{ toName !== null ? toName : '' }}</h3>
                <h4>{{ toArtist !== null ? toArtist : '' }}</h4>
              </div>

              <div>
              <v-btn
                outlined
                @click="reverseConnectionDirection()"
              >

                <v-icon
                  color="grey lighten-1"
                  left
                >
                  mdi-swap-horizontal
                </v-icon>
                Reverse connection
              </v-btn>
              </div>

            </template>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dismissSelf()"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="createConnectionData.requestInProgress"
            :disabled="!anItemIsSelectedForCreateConnection"
            color="blue darken-1"
            text
            @click="createConnection()"
          >
            Create connection
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import { endpoints } from '@/utils/endpoints';
import { CreateConnectionDirection } from '@/utils/enums';
import {
  playSong, appendToQueue, addToNextInQueue, fetchAlbumsForSong, getSongsByISRCId,
  getISRCFromPseudoISRC, fetchTracksInAlbum,
} from '@/utils/AppleMusicPlayer';
import RelatedSong from '@/components/RelatedSong.vue';

export default {
  name: 'CreateConnectionModal',
  props: {
    // The song the modal was launched from. This mediaItem will be in connection, either as
    // beginning of connection or end of connection.
    mediaItem: Object,
    showDialog: Boolean,
  },
  data() {
    return {
      createConnectionData: {
        fromItem: null,
        toItem: null,
        connectionDirection: CreateConnectionDirection.ConnectToSelected,
        requestInProgress: false,
      },
      selectedItemIndex: null,
    };
  },
  watch: {
    selectedItemIndex(newValue) {
      if (newValue === null) {
        // Item was deselected
        switch (this.createConnectionData.connectionDirection) {
          case CreateConnectionDirection.ConnectToSelected:
            this.createConnectionData.toItem = null;
            this.createConnectionData.fromItem = this.mediaItem;
            break;
          case CreateConnectionDirection.ConnectFromSelected:
            this.createConnectionData.fromItem = null;
            this.createConnectionData.toItem = this.mediaItem;
            break;
          default:
            throw new Error('Unhandled case: ', this.createConnectionData.connectionDirection);
        }
        return;
      }
      const selectedSong = this.searchResults[this.selectedItemIndex];
      switch (this.createConnectionData.connectionDirection) {
        case CreateConnectionDirection.ConnectToSelected:
          this.createConnectionData.toItem = selectedSong;
          this.createConnectionData.fromItem = this.mediaItem;
          break;
        case CreateConnectionDirection.ConnectFromSelected:
          this.createConnectionData.fromItem = selectedSong;
          this.createConnectionData.toItem = this.mediaItem;
          break;
        default:
          throw new Error('Unhandled case: ', this.createConnectionData.connectionDirection);
      }
    },
  },
  computed: {
    ...mapGetters('search', [
      'searchText',
      'searchResults',
      'fetchingSearchResults',
    ]),
    ...mapGetters('transitions', [
      'transitionsForISRCId',
      'fetchingRelatedSongs',
    ]),
    ...mapGetters('reactions', [
      'getReactionForIsrcId',
    ]),
    showSelf() {
      return this.showDialog;
    },
    toItemSet() {
      return this.createConnectionData.toItem !== null
      && this.createConnectionData.toItem !== undefined;
    },
    fromItemSet() {
      return this.createConnectionData.fromItem !== null
      && this.createConnectionData.fromItem !== undefined;
    },
    fromName() {
      if (this.fromItemSet) {
        return this.createConnectionData.fromItem.attributes.name;
      }
      return null;
    },
    fromArtist() {
      if (this.fromItemSet) {
        return this.createConnectionData.fromItem.attributes.artistName;
      }
      return null;
    },
    toName() {
      if (this.toItemSet) {
        return this.createConnectionData.toItem.attributes.name;
      }
      return null;
    },
    toArtist() {
      if (this.toItemSet) {
        return this.createConnectionData.toItem.attributes.artistName;
      }
      return null;
    },
    searchTextComponent: {
      get() {
        return this.searchText;
      },
      set(newValue) {
        this.setSearchText(newValue);
      },
    },
    anItemIsSelectedForCreateConnection() {
      return this.selectedItemIndex !== null;
    },
  },
  created() {
    this.prepComponent();
  },
  methods: {
    ...mapActions('search', [
      'startSearch',
    ]),
    ...mapMutations('search', [
      'setSearchText',
    ]),
    ...mapActions('transitions', [
      'fetchTransitionsForISRCId',
      'createTransition',
    ]),
    async prepComponent() {
      // Reset state
      this.createConnectionData.fromItem = null;
      this.createConnectionData.toItem = null;
      this.createConnectionData.connectionDirection = CreateConnectionDirection.ConnectToSelected;
      this.createConnectionData.requestInProgress = false;

      // Set up fromItem
      this.createConnectionData.fromItem = this.mediaItem;
    },
    reverseConnectionDirection() {
      const currentFromItem = this.createConnectionData.fromItem;
      const currentToItem = this.createConnectionData.toItem;
      this.createConnectionData.fromItem = currentToItem;
      this.createConnectionData.toItem = currentFromItem;
    },
    async createConnection() {
      const selectedSongIndex = this.selectedItemIndex;
      this.createConnectionData.requestInProgress = true;
      if (selectedSongIndex === undefined) {
        // User has not selected a song
        this.createConnectionData.requestInProgress = false;
        return;
      }
      const selectedSong = this.searchResults[selectedSongIndex];
      if (selectedSong.attributes.isrc === undefined) {
        // console.log('No ISRC on selectedSong, ', selectedSong);
        this.createConnectionData.requestInProgress = false;
        return;
      }

      try {
        await this.createTransition({
          fromMediaItem: this.createConnectionData.fromItem,
          toMediaItem: this.createConnectionData.toItem,
        });
        await this.fetchTransitionsForISRCId({ ISRCId: this.mediaItem.attributes.isrc });
      } finally {
        this.createConnectionData.requestInProgress = false;
        this.dismissSelf();
      }
    },
    dismissSelf() {
      this.$emit('closeCreateConnectionModal');
    },
  },
};
</script>
