function capitalize(value: string): string {
  if (!value) return '';
  const ouputValue = value.toString();
  return ouputValue.charAt(0).toUpperCase() + ouputValue.slice(1);
}

function allCaps(value: string): string {
  if (!value) return '';
  const ouputValue = value.toString();
  return ouputValue.toUpperCase();
}

function truncate(value: string, capLength: number): string {
  if (!value) {
    return '';
  }
  const outputValue = value.toString();
  const inputLength = outputValue.length;
  if (inputLength < capLength) {
    // No need to trim string
    return outputValue;
  }
  return `${outputValue.slice(0, capLength - 3)}...`;
}

// Named exports
export {
  capitalize,
  allCaps,
  truncate,
};
