<template>
  <v-list-item>
    <template v-if="searchHit === null">
      <v-list-item-content>
        <v-progress-circular indeterminate/>
      </v-list-item-content>
    </template>

    <template v-else>
      <!-- <v-list-item-avatar>
        <v-img
          :src="artworkUrl"
          :max-height="artworkHeight"
          :max-width="artworkHeight"
        />
      </v-list-item-avatar> -->
      <v-list-item-content>
          <v-list-item-title
            @click="navigateToSong()"
            v-text="searchHit.attributes.name"
          />
          <v-list-item-subtitle
            v-text="searchHit.attributes.artistName"
            @click="navigateToSong()"
          />
      </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        @click="wrapPlaySong()"
      >
        <v-icon color="grey lighten-1">mdi-play</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="wrapAppendToQueue()"
      >
        <v-icon color="grey lighten-1">mdi-playlist-plus</v-icon>
      </v-btn>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { mapMutations } from 'vuex';
import {
  playSong, appendToQueue, getSongsByISRCId, getISRCFromPseudoISRC,
} from '@/utils/AppleMusicPlayer';

export default {
  name: 'RelatedSong',
  props: {
    ISRCId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchHit: null,
    };
  },
  computed: {
    goToPath() {
      const pureISRC = getISRCFromPseudoISRC(this.searchHit.attributes.isrc);
      return {
        name: 'Song',
        params: {
          isrcId: pureISRC,
        },
      };
    },
    title() {
      return this.searchHit.attributes.name;
    },
    artist() {
      return this.searchHit.attributes.artistName;
    },
    itemType() {
      return this.searchHit.type;
    },
    artworkUrl() {
      return MusicKit.formatArtworkURL(this.searchHit.attributes.artwork,
        this.artworkHeight,
        this.artworkHeight);
    },
    artworkHeight() {
      return 120;
    },
  },
  async created() {
    // Fetch from Streaming Platform
    const results = await getSongsByISRCId(this.ISRCId);
    // eslint-disable-next-line prefer-destructuring
    this.searchHit = results[0];
  },
  methods: {
    wrapPlaySong() {
      playSong(this.searchHit);
    },
    wrapAppendToQueue() {
      appendToQueue(this.searchHit);
    },
    navigateToSong() {
      if (!this.searchHit.attributes.isrc) {
        return;
      }
      const isrc = getISRCFromPseudoISRC(this.searchHit.attributes.isrc);
      this.$router.push({
        name: 'Song',
        params: {
          isrcId: isrc,
        },
      });
    },
  },
};
</script>
