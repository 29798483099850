<template>
  <div class="home">
    <h1>Favorites</h1>
    <v-data-table
      :headers="tableHeaders"
      :items="uniqueReactions"
      :loading="ui.fetchingLikedSongs"
      loading-text="Loading your favorites...hang on"
      no-data-text="Looks like you haven't liked any songs yet"
      hide-default-footer
      hide-default-header
      disable-pagination
      @dblclick:row="songClicked"
    >
      <template v-slot:item.image="{ item }">
        <router-link
          :to="routeToAlbum(item)"
        >
        <v-img
          :src="artworkURLForMediaItem(item)"
          max-height="40.0"
          max-width="40.0"
        />
        </router-link>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="wrapPlaySong(item)"
        >
          mdi-play
        </v-icon>
        <v-icon
          small
          @click="wrapAppendToQueue(item)"
        >
          mdi-playlist-plus
        </v-icon>
      </template>
    </v-data-table>
    <template
      v-if="userTransitions.length > 0"
    >
    <h1
      class="mt-2"
    >
      Transitions
    </h1>
    <saved-transitions
      v-for="entry in userTransitions"
      :key="entry.Id"
      :fromISRC="entry.From"
      :toISRC="entry.To"
    />
    </template>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { endpoints } from '@/utils/endpoints';
import SavedTransitions from '@/components/SavedTransitions.vue';
import {
  playSong, appendToQueue, getSongsByISRCId, getPlayableSongByISRCId, getISRCFromPseudoISRC,
} from '@/utils/AppleMusicPlayer';

export default {
  name: 'Favorites',
  components: {
    SavedTransitions,
  },
  data() {
    return {
      tableHeaders: [
        {
          text: '',
          value: 'image',
          align: 'start',
        },
        {
          text: 'Title',
          value: 'attributes.name',
          align: 'start',
        },
        {
          text: 'Artist',
          value: 'attributes.artistName',
          align: 'start',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
        },
      ],
      ui: {
        fetchingLikedSongs: null,
      },
    };
  },
  computed: {
    ...mapGetters('reactions', [
      'reactions',
    ]),
    ...mapGetters('transitions', [
      'userTransitions',
    ]),
    uniqueReactions() {
      const uniqueReactions = {};
      const allReactions = this.reactions.forEach((entry) => {
        const isrcId = entry.attributes.isrc;
        uniqueReactions[isrcId] = entry;
      });

      return Object.values(uniqueReactions);
    },
    routeToAlbum() {
      return (mediaItem) => {
        const album = mediaItem.relationships.albums.data[0];
        return {
          name: 'Album',
          params: {
            albumId: album.id,
          },
        };
      };
    },
  },
  async mounted() {
    this.ui.fetchingLikedSongs = true;
    try {
      await this.fetchSongLikesFreshStart();
    } finally {
      this.ui.fetchingLikedSongs = false;
    }
    // TODO: Make below nicer!
    try {
      await this.fetchUsersTransitions();
    } catch (error) {
      console.log(`Error occured when fetching user's transitions.  ${error}`);
    }
  },
  methods: {
    ...mapActions('reactions', [
      'fetchSongLikesFreshStart',
    ]),
    ...mapActions('transitions', [
      'fetchUsersTransitions',
    ]),
    async wrapPlaySong(mediaItem) {
      playSong(mediaItem);
    },
    songClicked(_, { item }) {
      const pureISRC = getISRCFromPseudoISRC(item.attributes.isrc);
      this.$router.push({
        name: 'Song',
        params: {
          isrcId: pureISRC,
        },
      });
    },
    wrapAppendToQueue(mediaItem) {
      appendToQueue(mediaItem);
    },
    artworkURLForMediaItem(mediaItem) {
      return MusicKit.formatArtworkURL(mediaItem.attributes.artwork, 80.0, 80.0);
    },
  },
};
</script>
