import axios from 'axios';
import Vue from 'vue';
import { endpoints } from '@/utils/endpoints';
import {
  ReactionTypes,
  StreamingPlatforms,
  BackendInteractionProgress,
  StreamingPlatformInteractionProgress,
} from '@/utils/enums';
import {
  getISRCFromPseudoISRC,
  getPlayableSongByISRCId,
  isrcIsValid,
} from '@/utils/AppleMusicPlayer';
import { Reaction, Song } from '@/graphql/types';

interface ComponentData {
  mediaItem: MusicKit.MediaItem | null,
  apiInteractionProgress: StreamingPlatformInteractionProgress,
}

interface ReactionState {
  reactions: Array<MusicKit.MediaItem>,
  // A map of corresponding reactions associated reaction for ISRCId.
  ISRCIdMapping: { [key: string]: any }
}

const initialState: ReactionState = {
  //  Maps isrcId -> reaction
  reactions: [],
  ISRCIdMapping: {},
};

const getters = {
  getReactionForIsrcId: (state: ReactionState) => (isrcId: string) => {
    const isrc = getISRCFromPseudoISRC(isrcId);
    if (isrc === null) {
      console.debug('ISRC could not be extracted from ', isrcId);
      return undefined;
    }
    return state.ISRCIdMapping[isrc];
  },
  reactions: (state: ReactionState) => state.reactions,
};

const actions = {
  async likeSong(context: any, mediaItem: MusicKit.MediaItem) {
    // Strip it from any prefixes
    const isrc = getISRCFromPseudoISRC(mediaItem.attributes.isrc);
    const payload = {
      ISRCIdentifier: isrc,
      ReactionKind: ReactionTypes.Love,
    };
    try {
      await axios.post(endpoints.postSongReaction, payload, {
        withCredentials: true,
      });
      // Update likes locally
      context.dispatch('fetchSongLikesFreshStart');
    } catch (error) {
      console.error('Error here, ', error);
      throw error;
    }
  },
  async unlike() {
    // TODO: Implement here and in backend
  },
  async fetchSongLikesFreshStart(context: any) {
    // Clear/reset list
    context.commit('setReactions', []);
    await context.dispatch('fetchSongLikes');
  },
  async fetchSongLikes(context:any, cursorId: string) {
    const response = await axios.get(endpoints.getLikes, {
      withCredentials: true,
      params: {
        cursorId,
      },
    });

    response.data.forEach(async (reaction:Reaction) => {
      const song: Song = reaction.ReactsOn as Song;
      const canBeDisplayed = await isrcIsValid(song.ISRCIdentifier);
      if (!canBeDisplayed) {
        return;
      }
      const playableSongForID = await getPlayableSongByISRCId(song.ISRCIdentifier);
      if (playableSongForID === null) {
        return;
      }
      context.commit('addLikedSong', { likedSong: playableSongForID });

      // Also add to isrcIDMapping
      const reactionKind = reaction.ReactionKind;
      context.commit('updateISRCIdMapping', {
        ISRCIdentifier: song.ISRCIdentifier,
        reactionKind,
      });
    });
  },
};

const mutations = {
  setReactions(state: ReactionState, payload: Array<any>) {
    Vue.set(state, 'reactions', payload);
  },
  addLikedSong(state: ReactionState, { likedSong }:{likedSong:MusicKit.MediaItem}) {
    Vue.set(state.reactions, state.reactions.length, likedSong);
  },
  // eslint-disable-next-line max-len
  updateISRCIdMapping(state: ReactionState, { ISRCIdentifier, reactionKind }: { ISRCIdentifier:string, reactionKind: string }) {
    Vue.set(state.ISRCIdMapping, ISRCIdentifier, reactionKind);
  },
  setISRCIdMapping(state: ReactionState, newMapping: { [key: string]: any }) {
    Vue.set(state, 'ISRCIdMapping', newMapping);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
