interface UIState {
  showNavigationDrawer: boolean
}

const initialState: UIState = {
  showNavigationDrawer: true,
};

const getters = {
  showNavigationDrawer: (state: UIState) => state.showNavigationDrawer,
};

const actions = {

};

const mutations = {
  toggleShowNavigationDrawer(state:UIState) {
    state.showNavigationDrawer = !state.showNavigationDrawer;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
