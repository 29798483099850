async function playSong(mediaItem: MusicKit.MediaItem) {
  const music = MusicKit.getInstance();
  switch (mediaItem.type) {
    case 'albums':
      await music.setQueue({
        album: mediaItem.id,
        items: [], // Just to shut up IntelliSense
      });
      break;
    case 'songs':
      if (mediaItem.attributes.playParams === undefined) {
        // Still don't understand why this happens
        // Try with "Le Youth C O O L"
        // eslint-disable-next-line max-len
        const fullyPopulatedItem = await MusicKit.getInstance().api.song(mediaItem.id);
        const songsForCorrespondingISRC = await MusicKit.getInstance().api.songs([],
          {
            filter: {
              isrc: mediaItem.attributes.isrc,
            },
          });
        const firstMatch = songsForCorrespondingISRC
          .find(item => item.attributes.playParams !== undefined);
          // TODO: Look into:
          // For now, we'll blindly assume that there is always at least one match
        music.player.queue.prepend(firstMatch);
      } else {
        music.player.queue.prepend(mediaItem);
      }
      music.player.skipToNextItem();
      break;
    default:
      break;
  }
  music.play();
}

function appendToQueue(mediaItem: MusicKit.MediaItem) {
  const music = MusicKit.getInstance();
  if (mediaItem.type !== 'songs') {
    // console.log('Not a track, skipping');
    return;
  }
  music.player.queue.append(mediaItem);
}

async function seekToProgress(progress: number) {
  const music = MusicKit.getInstance();
  await music.player.seekToTime(progress);
}

async function getSongsByISRCId(ISRCId: string): Promise<Array<any>> {
  const music = MusicKit.getInstance();
  const results = await music.api.songs(
    [],
    {
      filter: {
        isrc: ISRCId,
      },
    },
  );
  return results;
}

async function getPlayableSongByISRCId(isrcID: string): Promise<MusicKit.MediaItem> {
  const results = await getSongsByISRCId(isrcID);
  return results.find(x => x.attributes.playParams !== undefined);
}

/**
 * Takes a "pseudo" ISRC code as input (one that may be prefixed by record-label name)
 * and returns the trailing last 12 characters (hopefully the ISRC code).
 * No verification is made as to whether the returned ISRC points to a valid record.
 */
function getISRCFromPseudoISRC(isrc: string): string|null {
  if (isrc.length < 12) {
    return null;
  }
  return isrc.substring(isrc.length - 12);
}

async function isrcIsValid(isrcID: string): Promise<boolean> {
  const { length } = isrcID;
  let isrcToUse = isrcID;
  if (length > 12) {
    // Extract the last 12 characters
    isrcToUse = isrcID.substring(length - 12);
  } else if (length < 12) {
    // This cannot be a valid ISRC
    return false;
  }
  try {
    await getSongsByISRCId(isrcToUse);
    return true;
  } catch {
    console.debug(`Failed to fetch: ${isrcToUse}`);
    return false;
  }
}

async function fetchAlbumsForSong(isrcId: string): Promise<Array<any>> {
  return getSongsByISRCId(isrcId);
}

async function fetchTracksInAlbum(albumId: string): Promise<Array<any>> {
  const album = await MusicKit.getInstance().api.album(albumId);
  return album.relationships.tracks;
}

async function addToNextInQueue(mediaItem: MusicKit.MediaItem) {
  const music = MusicKit.getInstance();
  if (mediaItem.type !== 'songs') {
    // console.log('Not a track, skipping');
    return;
  }
  music.player.queue.prepend(mediaItem);
}

function setVolume(newValue: number): void {
  if (newValue < 0 || newValue > 100) {
    console.debug('Invalid value provided when setting volume. Expected a number betwen 0 and 1'
    + 'Got ', newValue);
    return;
  }
  MusicKit.getInstance().player.volume = newValue / 100.0;
}

export {
  playSong,
  appendToQueue,
  getSongsByISRCId,
  addToNextInQueue,
  isrcIsValid,
  fetchAlbumsForSong,
  getISRCFromPseudoISRC,
  getPlayableSongByISRCId,
  fetchTracksInAlbum,
  setVolume,
  seekToProgress,
};
