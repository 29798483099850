
import axios from 'axios';
import { endpoints } from '@/utils/endpoints';
import { LoginProgress, LoginResponse } from '@/utils/enums';

interface AuthState {
  loginProgress: LoginProgress | null,
  loggedIn: boolean,
}

const initialState: AuthState = {
  loginProgress: null,
  loggedIn: false,
};

const getters = {
  loginProgress: (state: AuthState) => state.loginProgress,
  isLoggedIn: (state:AuthState) => state.loggedIn,
};

const actions = {
  async login(context: any, payload: any):
    Promise<LoginResponse> {
    try {
      context.commit('setLoginProgress', { newProgress: LoginProgress.AwaitingServerResponse });
      const response = await axios.post(endpoints.login, {
        email: payload.email,
        password: payload.password,
      }, {
        // We'll do manual inspection of status codes here
        validateStatus: () => true,
        timeout: 15000,
        // Setting withCredentials: true will allow api.edjy to set session-cookies
        withCredentials: true,
      });

      // Inspect status code
      if (response.status === 200) {
        // ... and signal we're done
        context.commit('setLoginProgress', { newProgress: LoginProgress.LoginSucceded });
        context.commit('setLoggedIn', { newValue: true });
        // Clear all state from previous sessions, so that we can reload it
        context.commit('clearStore', null, { root: true });
        return LoginResponse.Authorized;
      } if (response.status === 401) {
        // Wrong credentials
        context.commit('setLoginProgress', { newProgress: LoginProgress.Failed });
        return LoginResponse.WrongCredentials;
      }
      context.commit('setLoginProgress', { newProgress: LoginProgress.Failed });
      return LoginResponse.ServerError;
    } catch (error) {
      context.commit('setLoginProgress', { newProgress: LoginProgress.Failed });
      // console.log(error);
      return LoginResponse.ServerError;
    }
  },
  async signout(context: any) {
    try {
      await axios.post(endpoints.signout, null, {
        withCredentials: true,
      });
    } finally {
      context.commit('setLoggedIn', { newValue: false });
    }
  },
};

const mutations = {
  setLoginProgress(state: AuthState, { newProgress }: { newProgress: LoginProgress }) {
    state.loginProgress = newProgress;
  },
  setLoggedIn(state: AuthState, { newValue }: {newValue: boolean}) {
    state.loggedIn = newValue;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
