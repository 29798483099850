<template>
    <v-navigation-drawer
      app
      clipped
      :value="showNavigationDrawer"
    >
      <v-list
        rounded
        nav
      >

        <v-list-item
          v-for="item in navigations"
          :key="item.name"
          link
          @click="navigateTo(item.path)"
        >
          <v-list-item-icon>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.displayName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          key="account"
          link
          @click="navigateTo('Account')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          key="signout"
          link
          @click="doSignout()"
        >
          <v-list-item-icon>
            <v-icon>mdi-exit-run</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <template v-slot:append>
        <player></player>
      </template>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Player from '@/components/Player.vue';

export default {
  name: 'Sidebar',
  components: {
    Player,
  },
  data() {
    return {
      navigations: [
        {
          displayName: 'Favorites',
          path: 'Favorites',
          icon: 'heart',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', [
      'showNavigationDrawer',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'signout',
    ]),
    navigateTo(pathName) {
      this.$router.push({ name: pathName });
    },
    async doSignout() {
      try {
        await this.signout();
      } finally {
        // Stop any playing music
        MusicKit.getInstance().player.pause();

        // Navigate to login
        this.$router.push({ name: 'Login' });
      }
    },
  },
};
</script>
