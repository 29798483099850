<template>
  <div class="home">
    <v-row>
      <v-col
        align="center"
      >
        <v-img
          :src="artworkURL"
          width="200"
          height="200"
        />

      <!-- <h3
        v-if="albumData.relationships.artist.data.length > 1"
      >
        {{ albumArtist }}
      </h3> -->
      <router-link
        style="text-decoration: none; color: black"
        v-if="albumArtist !== null"
        :to="artistPath"
      >
        {{ albumArtist | truncate(29) }}
      </router-link>
      </v-col>
    </v-row>
    <!-- TODO: Controls to queue album -->
    <!-- List songs in album -->
    <v-data-table
      :headers="tableHeaders"
      :items="tracksInAlbum"
      hide-default-footer
      hide-default-header
    >

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
        >
          <v-icon
            small
            :color="songIsLiked(item.attributes.isrc) ? 'rgb(210,40,20)':'rgb(1,1,1)'"
            @click="wrapLikeSongInAlbum(item)"
          >
            {{ songIsLiked(item.attributes.isrc) ? 'mdi-heart': 'mdi-heart-outline'}}
          </v-icon>
        </v-btn>

        <v-icon
          small
          class="mr-2"
          @click="wrapPlaySong(item)"
        >
          mdi-play
        </v-icon>
        <v-icon
          small
          @click="wrapAppendToQueue(item)"
        >
          mdi-playlist-plus
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
// import { endpoints } from '@/utils/endpoints';
// import SavedTransitions from '@/components/SavedTransitions.vue';
// import {
//   playSong, appendToQueue, getSongsByISRCId, getPlayableSongByISRCId, getISRCFromPseudoISRC,
// } from '@/utils/AppleMusicPlayer';
import {
  playSong, appendToQueue,
} from '@/utils/AppleMusicPlayer';

export default {
  name: 'AlbumPage',

  data() {
    return {
      albumData: null,
      tableHeaders: [
        {
          text: 'Title',
          value: 'attributes.name',
          align: 'start',
        },
        {
          text: 'artist',
          value: 'attributes.artistName',
          align: 'start',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
        },
      ],
      ui: {
        fetchingLikedSongs: null,
        albumHeight: 200,
      },
    };
  },
  computed: {
    ...mapGetters('reactions', [
      'reactions',
      'getReactionForIsrcId',
    ]),
    ...mapGetters('transitions', [
      'userTransitions',
    ]),
    artworkURL() {
      if (this.albumData !== null && this.albumData.attributes.artwork !== null) {
        return MusicKit.formatArtworkURL(this.albumData.attributes.artwork,
          this.ui.albumHeight,
          this.ui.albumHeight);
      }
      return null;
    },
    albumArtist() {
      if (this.albumData !== null) {
        return this.albumData.attributes.artistName;
      }
      return null;
    },
    tracksInAlbum() {
      if (this.albumData === null) {
        return [];
      }
      return this.albumData.relationships.tracks.data;
    },
    albumId() {
      return this.$route.params.albumId;
    },
    songIsLiked() {
      return isrcId => this.getReactionForIsrcId(isrcId) !== undefined;
    },
    artistPath() {
      if (this.albumData.relationships === undefined) {
        debugger;
        return 'Cannot compute';
      }
      const artistsOnSong = this.albumData.relationships.artists.data;
      if (artistsOnSong !== undefined && artistsOnSong.length > 0) {
        const artist = artistsOnSong[0];
        const artistId = artist.id;
        return {
          name: 'Artist',
          params: {
            artistId,
          },
        };
      }
      // We could not figure out the artist ID.
      return null;
    },
  },
  mounted() {
    this.fetchAlbumInfo();
  },
  methods: {
    ...mapActions('reactions', [
      'fetchSongLikesFreshStart',
      'likeSong',
    ]),
    ...mapActions('transitions', [
      'fetchUsersTransitions',
    ]),
    wrapPlaySong(item) {
      playSong(item);
    },
    async fetchAlbumInfo() {
      const albumResult = await MusicKit.getInstance().api.album(this.albumId);
      this.albumData = albumResult;
      console.log(this.albumData);
    },
    async wrapLikeSongInAlbum(item) {
      await this.likeSong(item);
    },
  },
};
</script>
