<template>
    <div class="mt-5">

      <v-row>
      <v-col
        cols="3"
      >
        <v-img
          v-if="this.artworkURL !== null"
          :src="this.artworkURL"
          max-height="150"
          max-width="150"
        />
        <h3>{{ albumTitle }}</h3>
      </v-col>
      <v-col
        cols=9
      >
        <v-data-table
          :headers="tableHeaders"
          :items="tracksInAlbum"
          hide-default-footer
          hide-default-header
        >

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
            >
              <v-icon
                small
                :color="songIsLiked(item.attributes.isrc) ? 'rgb(210,40,20)':'rgb(1,1,1)'"
                @click="wrapLikeSongInAlbum(item)"
              >
                {{ songIsLiked(item.attributes.isrc) ? 'mdi-heart': 'mdi-heart-outline'}}
              </v-icon>
            </v-btn>

            <v-icon
              small
              class="mr-2"
              @click="wrapPlaySong(item)"
            >
              mdi-play
            </v-icon>
            <v-icon
              small
              @click="wrapAppendToQueue(item)"
            >
              mdi-playlist-plus
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      </v-row>
    </div>
</template>


<script>

import { mapGetters, mapActions } from 'vuex';
import {
  playSong, appendToQueue,
} from '@/utils/AppleMusicPlayer';

export default {
  name: 'Album',
  props: {
    album: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      albumData: null,
      tableHeaders: [
        {
          text: 'Title',
          value: 'attributes.name',
          align: 'start',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('reactions', [
      'getReactionForIsrcId',
    ]),
    artworkURL() {
      if (this.albumData !== null && this.albumData.attributes.artwork !== null) {
        return MusicKit.formatArtworkURL(this.albumData.attributes.artwork,
          this.artworkHeight,
          this.artworkHeight);
      }
      return null;
    },
    artworkHeight() {
      return 200.0;
    },
    albumTitle() {
      if (this.albumData !== null) {
        return this.albumData.attributes.name;
      }
      return null;
    },
    tracksInAlbum() {
      if (this.albumData === null) {
        return [];
      }
      return this.albumData.relationships.tracks.data;
    },
    songIsLiked() {
      return isrcId => this.getReactionForIsrcId(isrcId) !== undefined;
    },
  },
  mounted() {
    this.fetchAlbumInfo();
  },
  methods: {
    ...mapActions('reactions', [
      'likeSong',
    ]),
    async fetchAlbumInfo() {
      const albumResult = await MusicKit.getInstance().api.album(this.album.id);
      this.albumData = albumResult;
      // console.log(albumResult);
    },
    async wrapPlaySong(mediaItem) {
      playSong(mediaItem);
    },
    wrapAppendToQueue(mediaItem) {
      appendToQueue(mediaItem);
    },
    async wrapLikeSongInAlbum(item) {
      await this.likeSong(item);
    },
  },
};
</script>
