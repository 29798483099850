<template>
    <v-row>
        <v-col
            cols="12"
        >
            We're loading your settings, hang on! :)
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoadingPage',
  async mounted() {
    await this.fetchSongLikes();
    await this.fetchUsersTransitions();
    this.$router.push(this.$route.params.desiredPath || '/');
  },
  methods: {
    ...mapActions('reactions', [
      'fetchSongLikes',
    ]),
    ...mapActions('transitions', [
      'fetchUsersTransitions',
    ]),
  },
};
</script>
