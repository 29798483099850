import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth';
import players from '@/store/players';
import search from '@/store/search';
import transitions from '@/store/transitions';
import ui from '@/store/ui';
import reactions from '@/store/reactions';

Vue.use(Vuex);

const initialState = () => {};

export default new Vuex.Store({
  state: initialState(),
  mutations: {
    clearStore(state: any) {
      Object.assign(state, initialState());
    },
  },
  actions: {
  },
  modules: {
    auth,
    players,
    search,
    transitions,
    ui,
    reactions,
  },
});
