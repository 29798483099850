<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'Index',

  data: () => ({
    //
  }),
};
</script>
