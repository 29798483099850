<template>
<v-app>
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-alert
              v-if="serverErrorOccured"
              type="warning"
            >
              A server error occured
            </v-alert>

            <v-alert
              v-else-if="emailWasLikelySent"
              icon="mdi-email-outline"
              type="success"
            >
              We've emailed you instructions on how to update your password. <br>
              Check your inbox (including spam-folders) for an email from Edjy.io. <br>
              The link to update your password will expire in 30 minutes. <br><br>
              Note that an email was sent only if an account exists with the email you provided.
            </v-alert>

            <v-card>
              <v-card-title>
                Get a password reset email
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    name="login"
                    prepend-icon="mdi-account-circle"
                    type="text"
                    v-model="email"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="primary"
                  v-on:click="requestPasswordResetEmail"
                  :loading="backendInteractionInProgress"
                  :disabled="$v.$invalid ? true : false"
                >
                  Reset password
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </v-main>
</v-app>
</template>

<script>
import { validationMixin } from 'vuelidate';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { endpoints } from '@/utils/endpoints';
import { BackendInteractionProgress } from '@/utils/enums';

export default {
  name: 'ForgotPassword',
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      requestStatus: BackendInteractionProgress.NoOngoing,
    };
  },
  computed: {
    serverErrorOccured() {
      return this.requestStatus !== null
        && this.requestStatus === BackendInteractionProgress.Failed;
    },
    backendInteractionInProgress() {
      return this.requestStatus === BackendInteractionProgress.Ongoing;
    },
    emailWasLikelySent() {
      return this.requestStatus === BackendInteractionProgress.Successfull;
    },
  },
  methods: {
    async requestPasswordResetEmail() {
      // Invoke API
      const payload = {
        email: this.email,
      };
      try {
        this.requestStatus = BackendInteractionProgress.Ongoing;
        const response = await axios.post(endpoints.requestPasswordResetEmail, payload, {
          validateStatus: statusCode => statusCode === 200 || statusCode === 500,
        });
        if (response.statusCode === 500) {
          // Backend experienced an internal error, signal this to enduser
          this.requestStatus = LoginResponse.ServerError;
          this.requestStatus = BackendInteractionProgress.Failed;
        } else {
          this.requestStatus = BackendInteractionProgress.Successfull;
          // Move on: Disable 'Reset password' and instruct user what to do
        }
      } catch (error) {
        console.log('Error occured while trying to request a password reset', error);
        this.requestStatus = BackendInteractionProgress.Failed;
        throw error;
      }
    },
  },
  validations: {
    email: {
      required,
    },
  },
};
</script>
