import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import Index from './Index.vue';
import router from './router/router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vue-material-design-icons/styles.css';
import { MY_ICONS } from '@/assets/icons';
import { capitalize, allCaps, truncate } from '@/utils/filters';


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(Index),
}).$mount('#app');

Vue.use(Vuetify, {
  iconFont: 'mdi',
  icons: MY_ICONS,
});

// Configure filter on Vue instance
Vue.filter('capitalize', capitalize);
Vue.filter('allCaps', allCaps);
Vue.filter('truncate', truncate);
