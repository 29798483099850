import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../layout/Home.vue';
import SignIn from '../views/SignIn.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import SignUp from '../views/SignUp.vue';
import ConfirmSignUp from '../views/ConfirmSignUp.vue';
import Account from '../views/App/Account.vue';
import Song from '../views/App/Song.vue';
import AlbumPage from '../views/App/AlbumPage.vue';
import Favorites from '../views/App/Favorites.vue';
import ArtistPage from '@/views/App/ArtistPage.vue';
import LoadingPage from '@/views/App/LoadingPostLogin.vue';
import store from '@/store/index';

Vue.use(VueRouter);

/**
 * Higlevel description of routing: There are two toplevel URLs: Login and App
 * When you're logged in, you navigate between sublevel URLs of "App", such as
 * edgy.io/app/search or edgy.io/app/account
 */

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: SignIn,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/updatePassword/:resetId',
    name: 'ResetPassword',
    props: true,
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      requiresAuth: false,
    },
  },
  {
    // This path must be matched by link sent in Confirmation Email
    path: '/confirmsignup/:confirmationId',
    name: 'ConfirmSignUp',
    component: ConfirmSignUp,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/LoadUser',
        name: 'LoadUser',
        component: LoadingPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/Account',
        name: 'Account',
        component: Account,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/Favorites',
        name: 'Favorites',
        component: Favorites,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/Song/:isrcId',
        name: 'Song',
        component: Song,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/Artist/',
        name: 'Artist',
        component: ArtistPage,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/Album/:albumId',
        name: 'Album',
        component: AlbumPage,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/about',
    requiresLogin: false,
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function tokenSet() {
  return store.getters['auth/isLoggedIn'];
}

router.beforeEach((to, from, next) => {
  if (to === from) {
    next(false);
  }
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // Okay, we need to be logged in to proceed
    if (!tokenSet()) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      });
    } else {
      // We're logged in: we're good to go
      next();
    }
  } else {
    // Great, we don't need to be logged in to proceed
    next();
  }
});

export default router;
