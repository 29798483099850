import Vue from 'vue';
import { SearchProgress } from '@/utils/enums';

interface SearchState {
  searchText: string,
  searchResults: [],
  searchStatus: SearchProgress,
}

const initialState: SearchState = {
  searchText: '',
  searchResults: [],
  searchStatus: SearchProgress.NoOngoing,
};

const getters = {
  searchText: (state: SearchState) => state.searchText,
  searchResults: (state: SearchState) => state.searchResults,
  searchStatus: (state: SearchState) => state.searchStatus,
  // eslint-disable-next-line max-len
  fetchingSearchResults: (state: SearchState) => state.searchStatus === SearchProgress.SearchOngoing,
};

const actions = {
  async startSearch(context: any) {
    if (context.state.searchText === '') {
      context.commit('setSearchResults', { searchResults: [] });
      return;
    }
    const queryParams = {
      types: 'artists',
    };
    context.commit('setSearchStatus', SearchProgress.SearchOngoing);
    // TODO: error-handling (Use try-catch)
    const hits: any = await MusicKit.getInstance()
      .api.search(context.state.searchText, queryParams);
    const songs = hits.songs ? hits.songs.data : [];
    const albums = hits.albums ? hits.albums.data : [];
    const searchResults = songs.concat(albums);
    context.commit('setSearchResults', { searchResults });
    context.commit('setSearchStatus', SearchProgress.NoOngoing);
  },
};

const mutations = {
  setSearchStatus(state: SearchState, newValue: SearchProgress) {
    Vue.set(state, 'searchStatus', newValue);
  },
  setSearchResults(state: SearchState, { searchResults }: { searchResults:[] }) {
    Vue.set(state, 'searchResults', searchResults);
  },
  setSearchText(state: SearchState, searchText: string) {
    Vue.set(state, 'searchText', searchText);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
